import React, { useState, useEffect } from 'react';

const RegionSelector = ({ onRegionSelect }) => {
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        fetch('https://formulary-project-27ddbe295a42.herokuapp.com/get_data')
        .then(response => {
            if (response.headers.get('content-type') && response.headers.get('content-type').includes('application/json')) {

                return response.json();
            } else {
                console.error("Received non-JSON response:", response);
                return response.text().then(text => {
                    console.error("Response text:", text);
                    throw new Error("Received non-JSON response");
                });
            }
        })
        .then(data => setRegions(data.regions))
        .catch(error => {
            console.error("Fetching data failed:", error);
        });
    }, []);

    return (
        <div>
            <label>Select Region: </label>
            <select onChange={e => onRegionSelect(e.target.value)}>
                <option value="">--Select--</option>
                {regions.map(region => <option key={region} value={region}>{region}</option>)}
            </select>
        </div>
    );
}

export default RegionSelector;
