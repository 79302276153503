import React, { useState, useEffect } from 'react';

const DrugNameSelector = ({ selectedSubclass, onDrugNameSelect }) => {
    const [drugNames, setDrugNames] = useState([]);

    useEffect(() => {
        console.log('Current subclass:', selectedSubclass); 
        let cancelled = false;
        if (selectedSubclass) {
            // Encode the subclass
            const encodedSubclass = encodeURIComponent(selectedSubclass);
            
            // Use the encoded subclass in the fetch URL
            fetch(`https://formulary-project-27ddbe295a42.herokuapp.com/get_drug_names/${encodedSubclass}`)
                .then(response => {
                  console.log('Response received:', response); // Check the raw response
                  return response.json();
                })
                .then(data => {
                    console.log('Data received:', data); // Check the JSON data
                    if (!cancelled) {
                      setDrugNames(data.drug_names);
                      setTimeout(() => {
                        console.log('Delayed drug names:', drugNames);
                      }, 100);
                    }
                })              
                .catch(error => {
                    console.error('Error fetching drug names:', error); // Log any errors
                });
        }
        return () => {
            cancelled = true;
        };
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSubclass]);


    console.log('Rendering drug names:', drugNames); 
    return (
        <div>
            <label>Select Drug Name: </label>
            <select onChange={e => onDrugNameSelect(e.target.value)}>
                <option value="">--Select--</option>
                {drugNames.map(drugName => <option key={drugName} value={drugName}>{drugName}</option>)}
            </select>
        </div>
    );
}

export default DrugNameSelector;