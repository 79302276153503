import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import NotFound from './components/NotFound';

function App() {
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedSubclass, setSelectedSubclass] = useState("");
  const [drugDetails, setDrugDetails] = useState([]);

  // Handler to update the selected region and reset subclass
  const handleRegionSelect = (region) => {
    setSelectedRegion(region);
    // Resetting subclass when region changes
    setSelectedSubclass("");
    setDrugDetails([]);
  };

  // Handler to update the selected subclass
  const handleSubclassSelect = (subclass) => {
    setSelectedSubclass(subclass);
    setDrugDetails([]);
  };

  // Handler to update the selected drug name and fetch its details
  const handleDrugNameSelect = (drugName) => {
    // Only fetch drug details if both region and drug name are selected
    if (selectedRegion && drugName) {
      const encodedRegion = encodeURIComponent(selectedRegion);
      const encodedDrugName = encodeURIComponent(drugName);
      fetch(`https://formulary-project-27ddbe295a42.herokuapp.com/get_drug_details/${encodedRegion}/${encodedDrugName}`)
        .then(response => response.json())
        .then(data => {
          setDrugDetails(data.details);
        })
        .catch(error => console.error("Failed to fetch drug details:", error));
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <Home 
            onRegionSelect={handleRegionSelect}
            onSubclassSelect={handleSubclassSelect}
            onDrugNameSelect={handleDrugNameSelect}
            selectedRegion={selectedRegion}
            selectedSubclass={selectedSubclass}
            drugDetails={drugDetails}
          />
        } />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );    
}

export default App;
