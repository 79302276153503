// components/About.js
function About() {
    return (
      <div>
        <h1>About Us</h1>
        {/* Other content */}
      </div>
    );
  }
  
  export default About;
  