// components/NotFound.js
function NotFound() {
    return (
      <div>
        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
        <a href="/">Go Home</a>
      </div>
    );
  }
  
  export default NotFound;
  