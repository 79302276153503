import React, { useState, useEffect } from 'react';

const SubclassSelector = ({ selectedRegion, onSubclassSelect }) => {
    const [subclasses, setSubclasses] = useState([]);

    useEffect(() => {
        if (selectedRegion) {
            fetch(`https://formulary-project-27ddbe295a42.herokuapp.com/get_subclasses/${selectedRegion}`)
            .then(response => response.json())
            .then(data => {
                const filteredSubclasses = data.subclasses.filter(sub => sub !== "Subclass");
                console.log("Received subclasses:", filteredSubclasses);  // Debug line
                setSubclasses(filteredSubclasses);
            });
        }
    }, [selectedRegion]);    

    return (
        <div>
            <label>Select Subclass: </label>
            <select onChange={e => onSubclassSelect(e.target.value)}>
                <option value="">--Select--</option>
                {subclasses.map(subclass => <option key={subclass} value={subclass}>{subclass}</option>)}
            </select>
        </div>
    );
}

export default SubclassSelector;
