// Home.js
import React from 'react';
import RegionSelector from './RegionSelector';
import SubclassSelector from './SubclassSelector';
import DrugNameSelector from './DrugNameSelector';
import DrugDetails from './DrugDetails';

function Home({
  onRegionSelect,
  onSubclassSelect,
  onDrugNameSelect,
  selectedRegion,
  selectedSubclass,
  drugDetails
}) {
  return (
    <div className="Home">
      <RegionSelector onRegionSelect={onRegionSelect} />
      <SubclassSelector
        selectedRegion={selectedRegion}
        onSubclassSelect={onSubclassSelect}
      />
      <DrugNameSelector
        selectedSubclass={selectedSubclass}
        onDrugNameSelect={onDrugNameSelect}
      />
      <DrugDetails drugDetails={drugDetails} />
    </div>
  );
}

export default Home;
