import React from 'react';

const DrugDetails = ({ drugDetails }) => {
    return (
        <div>
            <h2>Drug Details</h2>
            <table>
                <thead>
                    <tr>
                        <th>Drug</th>
                        <th>Tier</th>
                        <th>Requirement</th>
                    </tr>
                </thead>
                <tbody>
                    {drugDetails.map((detail, index) => (
                        <tr key={index}>
                            <td>{detail.full_drug_details}</td>
                            <td>{detail.tier}</td>
                            <td>{detail.requirement}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DrugDetails;
